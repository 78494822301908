// Animation -- USAGE
// @include keyframes(fade-out) {
//     0% {  }
//     90% {  }
// }
// .element {
//     @include animation('fade-out 5s 3');
// }

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($animStr) {
  -webkit-animation: #{$animStr};
  -moz-animation: #{$animStr};
  -ms-animation: #{$animStr};
  -o-animation: #{$animStr};
  animation: #{$animStr};
}

// TRANSITIONS
// Usage
// @include transition(all .3 ease-in);

@mixin transition ($properties) {
    -webkit-transition: $properties;
    -moz-transition: $properties;
    -ms-transition: $properties;
    -o-transition: $properties;
    transition: $properties;
}

// parent diff.
// use when the element need different properties inside a specific parent
// use any number of classes
// @include parent-diff(class1, class2, class3, ...) {
// }

@mixin parent-diff($classes...) {
    @each $class in $classes {
        .#{$class} & {
            @content;
        }
    }
}

// FONTS
//   default: from: https://www.developerdrive.com/10-best-sass-mixins-for-web-developers/
//   in this solution you need just change the font-weight to get the font variations
//   @include font-face("My Font", my-font, font-weight);

@mixin font-face($fontName, $fontSrc, $fontweight) {
    @font-face {
    font-family: "#{$fontName}";
    src: url("../fonts/#{$fontSrc}.eot");
    src: url("../fonts/#{$fontSrc}.eot?#iefix") format("embedded-opentype"),
    url("../fonts/#{$fontSrc}.woff") format("woff"),
    url("../fonts/#{$fontSrc}.ttf") format("truetype"),
    url("../fonts/#{$fontSrc}.svg?#webfont") format("svg");
    font-weight: $fontweight;
  }
}

//setting the body default font size depending on the breakpoints - only use just bootstrap 4 breakpoints
//usage:
// @include setting-font-size();    -- use default settings
// @include setting-font-size(18,18,22,24,26);

@mixin setting-font-size ( $font-xs: 14px, $font-sm: 14px, $font-md: 14px, $font-lg: 16px, $font-xl: 16px){
    $body-font-sizes: (
        xs: $font-xs,
        sm: $font-sm,
        md: $font-md,
        lg: $font-lg,
        xl: $font-xl
    );

    @each $breakpoint-name, $screen-breakpoint in $screen-breakpoints {
        @media only screen and (min-width: #{map-get($screen-breakpoints, $breakpoint-name)}) {
            font-size: map-get($body-font-sizes, $breakpoint-name);
        }
    }

}

@function asset($file, $imagePath: $imagePath) {
    @return $imagePath + $file;
  }
