#search-page{
    .mobil-filter-button{
        border: 2px solid $color-text-gray-6;
        padding: 10px;
        cursor: pointer;
        &:hover{
            border-color: $color-action-blue-hover;
        }
    }

    .overlay{
        .filters{
            h6{
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 32px;
                border-bottom: 1px solid $color-text-gray-6;
                padding-top: 16px;
                svg{
                    display: none;
                }
            }

            ul.categories, ul.categories ul, ul.categories li{
                @extend %list-none;
            }

            ul.categories > li{
                padding: 12px 0 12px 16px;
                a{
                    position: relative;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: $color-text-gray-1;
                    width: 100%;
                    svg{
                        position: absolute;
                        top: 6px;
                        right: 0;
                        @extend %defTransition;
                    }
                }


                > ul {
                    display: none;
                    padding: 8px 0;
                    > li {
                        padding: 8px 0;
                    }
                }

                &.open{
                    a{
                        svg{
                            transform: rotate(180deg);
                            @extend %defTransition;
                        }
                    }
                    > ul{
                        display: block;
                    }
                }
            }

            .clear-filter{
                font-family: Nexa;
                font-style: normal;
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
                text-transform: uppercase;
            }

            @media #{$screen-max-sm}{
                position: fixed;
                top: 0;
                left: -81vw;
                background: $color-white;
                width: 80vw;
                height: 100%;
                padding: 0 0 0 16px;
                max-width: 300px;
                overflow: hidden;
                overflow-y: auto;

                h6 svg{
                    display: block;
                    position: absolute;
                    top: 14px;
                    right: 14px;
                    z-index: 1000;
                    cursor: pointer;
                }

                > ul{
                    padding-right: 16px !important;
                    overflow: hidden;
                    overflow-y: auto;
                    height: calc(100vh - 57px - 53px);
                }
            }
        }

        @media #{$screen-max-sm}{
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #28282899;
            z-index: 100;
        }
    }

    .results{
        .item{
            > div > div{
                background: $color-text-gray-3;
                text-align: center;
                img{
                    height: auto;
                    width: auto;

                    @media #{$screen-max-xs} {
                        max-width: calc(100vw / 3);
                    }

                    @media #{$screen-sm} {
                        max-width: 240px;
                        max-height: 141px;
                    }

                    @media #{$screen-md} {
                        max-width: 210px;
                        max-height: 123px;
                    }

                    @media #{$screen-xl} {
                        max-width: 255px;
                        max-height: 150px;
                    }
                }

                @media #{$screen-max-xs} {
                    width: calc(100vw / 3);
                    margin-right: 16px;
                }

                @media #{$screen-sm} {
                    width: 240px;
                    height: 141px;
                }

                @media #{$screen-md} {
                    width: 210px;
                    height: 123px;
                }

                @media #{$screen-xl} {
                    width: 255px;
                    height: 150px;
                }
            }

            h6{
                font-family: "Roboto Bold";
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

}
