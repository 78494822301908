#marketing-support{
    &.categories{
        .list{
            .item{
                > div{
                    background: $color-text-gray-3;
                    text-align: center;
                    img{
                        height: auto;
                        width: auto;

                        @media #{$screen-max-xs} {
                            max-width: 100%;
                        }

                        @media #{$screen-sm} {
                            max-width: 240px;
                            max-height: 141px;
                        }

                        @media #{$screen-md} {
                            max-width: 210px;
                            max-height: 123px;
                        }

                        @media #{$screen-xl} {
                            max-width: 255px;
                            max-height: 150px;
                        }
                    }

                    @media #{$screen-max-xs} {
                        width: 100%;
                    }

                    @media #{$screen-sm} {
                        width: 240px;
                        height: 141px;
                    }

                    @media #{$screen-md} {
                        width: 210px;
                        height: 123px;
                    }

                    @media #{$screen-xl} {
                        width: 255px;
                        height: 150px;
                    }
                }

                &.inactive{
                    opacity: 0.6;
                }
            }

        }

        &.sub .item h6{
            font-family: 'Roboto bold';
            font-size: 14px;
        }
    }

    &.categories.main{
        .list{
            .item{
                > div{
                    img{
                        @media #{$screen-lg} {
                            max-width: 290px;
                            max-height: 173px;
                        }

                        @media #{$screen-xl} {
                            max-width: 345px;
                            max-height: 205px;
                        }
                    }

                    @media #{$screen-lg} {
                        width: 290px;
                        height: 173px;
                    }

                    @media #{$screen-xl} {
                        width: 345px;
                        height: 205px;
                    }
                }

                &.inactive{
                    opacity: 0.6;
                }
            }

        }

        &.sub .item h6{
            font-family: 'Roboto bold';
            font-size: 14px;
        }
    }

    &.product{
        .gallery{
            position: relative;
            .viewer{
                position: relative;
                background: $color-text-gray-3;
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(100vw - 60px);
                > div{
                    position: relative;
                    img{
                        width: auto;
                        height: auto;
                        max-width: calc(100vw - 60px);
                        max-height: calc(100vw - 60px);
                        @media #{$screen-sm} {
                            max-width: 510px;
                            max-height: 510px;
                        }

                        @media #{$screen-md} {
                            max-width: 690px;
                            max-height: 690px;
                        }

                        @media #{$screen-lg} {
                            max-width: 450px;
                            max-height: 450px;
                        }

                        @media #{$screen-xl} {
                            max-width: 540px;
                            max-height: 540px;
                        }

                        &:nth-child(2){
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }

                @media #{$screen-sm} {
                    height: 510px;
                }

                @media #{$screen-md} {
                    height: 690px;
                }

                @media #{$screen-lg} {
                    height: 450px;
                }

                @media #{$screen-xl} {
                    height: 540px;
                }
            }

            .video{
                position: relative;
                cursor: pointer;
                &:before{
                    content: '';
                    background-color: #{$color-text-gray-5}99;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }

                &:after{
                    content: '';
                    background: url(asset('svg/video-play-button.svg'));
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 72px;
                    height: 72px;
                    z-index: 3;
                    margin: -36px 0 0 -36px;
                }

                &:hover:after{
                    opacity: .8;
                }
            }

            video{
                width: auto;
                height: auto;
                max-width: calc(100vw - 60px);
                max-height: calc(100vw - 60px);
                @media #{$screen-sm} {
                    max-width: 510px;
                    max-height: 510px;
                }

                @media #{$screen-md} {
                    max-width: 690px;
                    max-height: 690px;
                }

                @media #{$screen-lg} {
                    max-width: 450px;
                    max-height: 450px;
                }

                @media #{$screen-xl} {
                    max-width: 540px;
                    max-height: 540px;
                }
            }


            .thumbs{
                position: relative;
                > div{
                    overflow: hidden;
                    height: 64px;
                    margin-top: 14px;
                    > div{
                        overflow: hidden;
                        overflow-x: scroll;
                        ul{
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            width: fit-content;
                            > li{
                                background-position: center;
                                background-size: cover;
                                display: inline-block;
                                vertical-align: top;
                                position: relative;
                                list-style: none;
                                padding: 0;
                                margin: 0 14px 14px 0;
                                width: 60px;
                                height: 60px;
                                cursor: pointer;
                                @extend %defTransition;

                                &:last-child{
                                    margin-right: 0;
                                }

                                &:hover, &.active{
                                    &:after{
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        background: #{$color-action-blue} + 'd9';
                                        @extend %defTransition;
                                    }
                                }
                            }
                        }
                    }
                }

                .left, .right{
                    margin: 0;
                    position: absolute;
                    top: calc(50% - 12px);
                    width: 24px;
                    height: 24px;
                    background-image: url(asset('svg/arrow-left.svg'));
                    background-repeat: no-repeat;
                    background-size: 40%;
                    background-position: center;
                    background-color: $color-text-gray-6;
                    z-index: 10;
                    cursor: pointer;
                    @extend %defTransition;
                    &:hover{
                        background-color: $color-text-gray-4;
                    }
                }
                .left{
                    left: -32px;
                }
                .right{
                    right: -32px;
                    transform: rotate(180deg);
                }

                @media #{$screen-max-md}{
                    margin: 0 32px;
                }
            }
        }

        .description{
            position: relative;
            margin: 0 40px;
            border: 1px solid $color-text-gray-4;
            padding: 32px 64px;

            hr{
                margin: 32px 0;
                &:last-child{
                    display: none;
                }
            }

            .btn{
                font-family: "Nexa heavy";
            }

            .budget{
                position: absolute;
                right: 16px;
                top: 16px;
            }

            @media #{$screen-max-lg}{
                padding: 16px 32px;
            }
            @media #{$screen-max-md}{
                margin: 0;
                padding: 0;
                border: none;
                .budget{
                    position: absolute;
                    right: 0px;
                    top: -16px;
                }
            }
        }
    }

    .budget{
        position: absolute;
        right: 32px;
        top: 32px;
        border-radius: 4px;
        &.new{
            background: $color-action-blue;
            color: $color-white;
            font-family: 'Nexa heavy';
            font-weight: bold;
            font-style: normal;
            font-size: 12px;
            padding: 2px 8px;
        }
    }
}
