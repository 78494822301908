#success-page{
    width: 100%;

    .content,
    .image{
        @media #{$screen-md} {
            height: 100vh;
        }
    }

    .image{
        background-size: cover;
        background-position: center;
    }

    .content{
        .content-box{
            height: calc(100vh - 80px);
            @media #{$screen-md} {
                height: calc(100vh - 200px);

            }
        }
    }
}
