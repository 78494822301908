header{
    background-color: $color-white;
    border-bottom: 1px solid $color-text-gray-4;
    padding: 9px 0;
    height: 73px;
    box-sizing: border-box;

    .logo-col{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    nav.main{
        padding: 15px 0;

        a{
            font-family: 'Nexa heavy';
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            margin: 0 16px;
            text-transform: uppercase;
            color: $color-text-gray-1;
            &:hover{
                color: $color-action-blue-hover;
            }

            &:active, &.active{
                 color: $color-action-blue-active;
            }
        }
    }

    .secondary-menu{
        > ul{
            margin: 0;
            padding: 0;
            list-style: none;
           > li{
                @extend ul;
                display: inline-block;
                vertical-align: middle;
                padding: 15px 0;
                cursor: pointer;

                &.profile{ padding-left: 32px;}

                .submenu{
                    @extend %defTransition;
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    top:40px;
                    right: 16px;
                    border: 1px solid $color-text-gray-4;
                    background: $color-white;
                    min-width: 256px;
                    list-style: none;
                    margin: 8px 0;
                    padding: 0;
                    z-index: 10;

                    li{
                        list-style: none;
                        margin: 0;
                        a{
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: left;

                            border-left: 2px solid $color-white;
                            color: $color-text-gray-1;
                            padding: 12px 32px;
                            width: 100%;
                            display: block;
                            &:hover{
                                border-left: 2px solid $color-action-blue;
                                background: #1babf91a;
                                color: $color-action-blue-hover;
                            }

                            &:active, &.active{
                                border-left: 2px solid $color-action-blue;
                                background: #1babf91a;
                                color: $color-action-blue-active;
                            }
                        }
                    }
                }

                &.search{
                    position: relative;
                    margin-top: -2px;
                    .search-box{
                        @extend %defTransition;
                        position: absolute;
                        padding: 10px;
                        border-radius: 100px;
                        top: -6px;
                        right: -10px;
                        text-align: left;
                        .search-icon{
                            display: inline-block;
                            margin-left: 20px;
                        }

                        input{
                            position: absolute;
                            top: 7px;
                            left: 54px;
                            background: transparent;
                            border: none;
                            height: 32px;
                            width: 90%;
                            display: none;
                            &:focus{
                                box-shadow: none;
                            }
                        }

                        .result{
                            display: none;
                            background: $color-white;
                            position: absolute;
                            top: 60px;
                            left: 0;
                            width: 100%;
                            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
                            z-index: 9;
                            ul{
                                list-style: none;
                                padding: 16px 0;

                                li{
                                    list-style: none;
                                    margin: 0 16px;
                                    a{
                                        font-family: Roboto;
                                        font-style: normal;
                                        font-weight: bold;
                                        font-size: 16px;
                                        line-height: 24px;
                                        text-align: left;

                                        color: $color-text-gray-1;
                                        padding: 12px 24px;
                                        display: block;
                                        border-radius: 4px;
                                        box-sizing: border-box;

                                        small{
                                            display: block;
                                        }
                                    }
                                    &:hover{
                                        background: #1babf91a;
                                    }

                                    &:active, &.active{
                                        background: #1babf91a;
                                    }
                                }
                            }
                        }
                    }

                    .m-close{
                        display: none;
                        position: absolute;
                        top: 4px;
                        right: 0;
                    }

                    &.active{
                        .search-box{
                            background: $color-text-gray-6;
                        }
                    }
                }

                &.hamburger-menu{
                    padding-left: 32px;
                    > .menu{
                        display: none;
                        position: fixed;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: #28282899;
                        z-index: 100;
                        text-align: left;

                        .m-close{
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            z-index: 1000;

                            @media #{$screen-max-xs}{
                                top: 28px;
                                right: 16px;
                            }
                        }

                        > ul{
                            position: absolute;
                            top: 0;
                            right: -81vw;
                            width: 80vw;
                            max-width: 500px;
                            min-height: 100vh;
                            background: $color-white;
                            padding: 64px 0px 16px;
                            margin: 0;
                            list-style: none;

                            > li{
                                list-style: none;
                                margin: 0;
                                > a{
                                    position: relative;
                                    font-family: Roboto;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: $color-text-gray-1;
                                    padding: 8px 32px 8px;
                                    width: 100%;
                                    display: block;
                                    border-left: 2px solid $color-white;
                                    &:hover{
                                        border-left: 2px solid $color-action-blue;
                                        background: #1babf91a;
                                        color: $color-action-blue-hover;
                                    }

                                    &:active, &.active{
                                        border-left: 2px solid $color-action-blue;
                                        background: #1babf91a;
                                        color: $color-action-blue-active;
                                    }
                                }

                                &.hr{
                                    border-top: 1px solid $color-text-gray-4;
                                }

                                &.main{
                                    > a:after{
                                        position: absolute;
                                        content: '';
                                        background: url(asset('svg/arrow-down.svg')) no-repeat;
                                        top: 50%;
                                        right: 23px;
                                        margin-top: -8px;
                                        width: 16px;
                                        height: 16px;
                                        @extend %defTransition;
                                    }

                                    &.open{
                                        > a:after{
                                            transform: rotate(180deg);
                                            @extend %defTransition;
                                        }
                                    }
                                }

                                > ul{
                                    display: none;
                                    padding: 0px;
                                    margin: 0;
                                    list-style: none;

                                    > li{
                                        list-style: none;
                                        margin: 0;
                                        > a{

                                            font-family: Roboto;
                                            font-style: normal;
                                            font-weight: normal;
                                            font-size: 14px;
                                            line-height: 18px;
                                            color: $color-text-gray-2;
                                            padding: 12px 32px;
                                            width: 100%;
                                            display: block;
                                            border-left: 2px solid $color-white;
                                            &:hover{
                                                border-left: 2px solid $color-action-blue;
                                                background: #1babf91a;
                                                color: $color-action-blue-hover;
                                            }

                                            &:active, &.active{
                                                border-left: 2px solid $color-action-blue;
                                                background: #1babf91a;
                                                color: $color-action-blue-active;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.search:hover{
                    svg{
                        path{
                            stroke: $color-action-blue-hover;
                        }
                    }
                }

                &.profile:hover, &.profile.active{
                    svg{
                        path{
                            fill: $color-action-blue;
                            stroke: $color-action-blue;
                        }
                    }
                }

                &:hover .submenu{
                    visibility: visible;
                    opacity: 1;
                    @extend %defTransition;
                }
            }
        }
    }
}


@media #{$screen-md}{
    body.no-header-footer header{
        display: none;
    }
}


#breadcumb{
    background: $color-black-2;
    padding: 15px 0 13px;
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
       > li{
            @extend ul;
            display: inline-block;
            vertical-align: middle;
            color: $color-text-gray-8;

            a{
                font-family: "Roboto bold";
                font-size: 14px;
                color: $color-white;
                &:hover, &:active{
                    color: $color-text-gray-8;
                }
            }

            @media #{$screen-max-sm}{
                &:not(.no):before{
                    content: url(asset('svg/breadcumb-arrow-left.svg'));
                    position: relative;
                    top: -3px;
                    margin: 0 12px 0 0;
                }
            }
            @media #{$screen-md}{
                &:not(:last-child):after{
                    content: url(asset('svg/breadcumb-arrow-right.svg'));
                    position: relative;
                    top: -3px;
                    margin: 0 24px;
                }
            }
        }

        @media #{$screen-max-sm}{
            &.mobil{ display: block; }
            &.desktop{ display: none; }
        }
        @media #{$screen-md}{
            &.desktop{ display: block; }
            &.mobil{ display: none; }
        }
    }
}
