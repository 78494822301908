#success-modal{
    .modal-dialog{
        top: calc(100vh / 6);

        .modal-content{
            padding: 48px 32px;
            @media #{$screen-max-sm}{
                padding: 24px 16px;
            }
        }
    }
}

.video{
    position: relative;
    cursor: pointer;
    &:not(.play):before{
        content: '';
        background-color: #{$color-text-gray-5}99;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &:not(.play):after{
        content: '';
        background: url(asset('svg/video-play-button.svg'));
        position: absolute;
        top: 50%;
        left: 50%;
        width: 72px;
        height: 72px;
        z-index: 3;
        margin: -36px 0 0 -36px;
    }

    &:not(.play):hover:after{
        opacity: .8;
    }
}
