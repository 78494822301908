#contact-us{
    .header-images{
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        height: 45vh;
        background: url(asset("contact_us.jpg"));
        background-position: top center;
        background-size: cover;
        z-index: -1;

        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #{$color-black}99;
        }
    }

    h4{
        color: $color-white;
    }

    form{
        background-color: $color-white;
        padding: 32px;
        border: 1px solid $color-text-gray-4;
        @media #{$screen-max-xs}{
            border: none;
        }
    }
}
