img,
a,
input,
button {
    outline: none !important; 
    
    &:focus,
    &:hover {
        //disable chrome blue border
        outline:0;
        text-decoration: none;
        @extend %defTransition;
    }
}

textarea{
    resize: none;
}
