#profile{
    h4{
        margin: 96px 0 32px;
        @media #{$screen-max-xs}{
            margin: 48px 0 0;
        }
    }
    form{
        background-color: $color-white;
        padding: 32px;
        border: 1px solid $color-text-gray-4;
        @media #{$screen-max-xs}{
            border: none;
        }
    }
}
