body{
    padding:0;
    margin:0;
    font-family: 'Roboto';
    color: $color-text-gray-1;
    font-size:$font-size-normal;
    background: $color-white;
}

.hidden {
    display: none !important;
}

a,
button.link{
    position: relative;
    border: none;
    background: none;
    display: inline-block;
    color:$color-action-blue;
    font-family: 'Nexa bold';
    font-style: normal;
    font-size: $font-size-normal;

    span svg{
        display: inline-block;
        margin-top: -4px;
        margin-right: 16px;
        height: 16px
    }

    &.roboto{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }

    &.black{
        color:$color-black;
        font-size: $font-size-medium;
        span svg{
            path{
                fill: $color-black;
            }
        }
        &:hover, &:active{
            color: $color-black-1;
            span svg{
                path{
                    fill: $color-black-1;
                }
            }
        }
    }

    &.white{
        color: $color-white;
        span svg{
            path{
                fill: $color-white;
            }
        }
    }

    &:hover, &.white:hover{
       color: $color-action-blue-hover;
       span svg{
            path{
                fill: $color-action-blue-hover;
            }
        }
    }

    &:active, &.white:active{
        color: $color-action-blue-active;
        span svg{
             path{
                 fill: $color-action-blue-active;
             }
         }
    }
}

a.link{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $color-action-blue-active;
    &:hover{
        color: $color-action-blue;
    }
}

strong, b{font-weight: bold;}


a,
input[type="text"],
textarea,
.btn,
.transition {
    @extend %defTransition;
}

.nowrap{
    white-space: nowrap;
}

.noscroll{
    overflow: hidden;
}

.whitespace-nowrap{
    white-space: nowrap;
}

textarea{
    min-height: 120px!important;
}

.text-decoration-underline{
    text-decoration: underline;
}

.hide{
    display: none;
}

main{
    min-height: $main-height;
}

p{
    margin-bottom: 32px;
}

.main-height{
    height: $main-height;
}


.m-8{margin: 8px !important;}
.mt-8{margin-top: 8px !important;}
.mb-8{margin-bottom: 8px !important;}
.ml-8{margin-left: 8px !important;}
.mr-8{margin-right: 8px !important;}

.m-16{margin: 16px !important;}
.mt-16{margin-top: 16px !important;}
.mb-16{margin-bottom: 16px !important;}
.ml-16{margin-left: 16px !important;}
.mr-16{margin-right: 16px !important;}

.m-24{margin: 24px !important;}
.mt-24{margin-top: 24px !important;}
.mb-24{margin-bottom: 24px !important;}
.ml-24{margin-left: 24px !important;}
.mr-24{margin-right: 24px !important;}

.m-32{margin: 32px !important;}
.mt-32{margin-top: 32px !important;}
.mb-32{margin-bottom: 32px !important;}
.ml-32{margin-left: 32px !important;}
.mr-32{margin-right: 32px !important;}

.m-40{margin: 40px !important;}
.mt-40{margin-top: 40px !important;}
.mb-40{margin-bottom: 40px !important;}
.ml-40{margin-left: 40px !important;}
.mr-40{margin-right: 40px !important;}

.m-48{margin: 48px !important;}
.mt-48{margin-top: 48px !important;}
.mb-48{margin-bottom: 48px !important;}
.ml-48{margin-left: 48px !important;}
.mr-48{margin-right: 48px !important;}

.m-56{margin: 56px !important;}
.mt-56{margin-top: 56px !important;}
.mb-56{margin-bottom: 56px !important;}
.ml-56{margin-left: 56px !important;}
.mr-56{margin-right: 56px !important;}

.m-80{margin: 80px !important;}
.mt-80{margin-top: 80px !important;}
.mb-80{margin-bottom: 80px !important;}
.ml-80{margin-left: 80px !important;}
.mr-80{margin-right: 80px !important;}

@media #{$screen-md}{
    .m-md-80{margin: 80px !important;}
    .mt-md-80{margin-top: 80px !important;}
    .mb-md-80{margin-bottom: 80px !important;}
    .ml-md-80{margin-left: 80px !important;}
    .mr-md-80{margin-right: 80px !important;}
}

.m-96{margin: 96px !important;}
.mt-96{margin-top: 96px !important;}
.mb-96{margin-bottom: 96px !important;}
.ml-96{margin-left: 96px !important;}
.mr-96{margin-right: 96px !important;}

.p-8{padding: 8px !important;}
.pt-8{padding-top: 8px !important;}
.pb-8{padding-bottom: 8px !important;}
.pl-8{padding-left: 8px !important;}
.pr-8{padding-right: 8px !important;}

.p-16{padding: 16px !important;}
.pt-16{padding-top: 16px !important;}
.pb-16{padding-bottom: 16px !important;}
.pl-16{padding-left: 16px !important;}
.pr-16{padding-right: 16px !important;}

.p-24{padding: 24px !important;}
.pt-24{padding-top: 24px !important;}
.pb-24{padding-bottom: 24px !important;}
.pl-24{padding-left: 24px !important;}
.pr-24{padding-right: 24px !important;}

.p-32{padding: 32px !important;}
.pt-32{padding-top: 32px !important;}
.pb-32{padding-bottom: 32px !important;}
.pl-32{padding-left: 32px !important;}
.pr-32{padding-right: 32px !important;}

.p-40{padding: 40px !important;}
.pt-40{padding-top: 40px !important;}
.pb-40{padding-bottom: 40px !important;}
.pl-40{padding-left: 40px !important;}
.pr-40{padding-right: 40px !important;}

.p-48{padding: 48px !important;}
.pt-48{padding-top: 48px !important;}
.pb-48{padding-bottom: 48px !important;}
.pl-48{padding-left: 48px !important;}
.pr-48{padding-right: 48px !important;}

.p-56{padding: 56px !important;}
.pt-56{padding-top: 56px !important;}
.pb-56{padding-bottom: 56px !important;}
.pl-56{padding-left: 56px !important;}
.pr-56{padding-right: 56px !important;}

.p-80{padding: 80px !important;}
.pt-80{padding-top: 80px !important;}
.pb-80{padding-bottom: 80px !important;}
.pl-80{padding-left: 80px !important;}
.pr-80{padding-right: 80px !important;}

.p-96{padding: 96px !important;}
.pt-96{padding-top: 96px !important;}
.pb-96{padding-bottom: 96px !important;}
.pl-96{padding-left: 96px !important;}
.pr-96{padding-right: 96px !important;}
