#login{
    background-image: url(asset('login_bg_D.jpg'));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: $main-height;
    display: flex;
    align-items: center;
    position: relative;

    @media #{$screen-max-xs}{
        background-image: url(asset('login_bg_M.jpg'));

    }
    @media #{$screen-max-sm}{
        &::before{
            content: '';
            background-color: #282828cc;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    #login-form{
        margin: 0 auto;
        padding: 40px 72px 66px;
        background-color: #282828cc;
        width: 100%;
        max-width: 544px;
        z-index: 1;
        .alert-text-wrapper{
            text-align: center;
            margin: 0;
            font-size: $font-size-normal;
            font-weight: bold;
            color: $color-action-red;
        }

        @media #{$screen-max-sm}{
            background: none;
            padding: 20px 24px;
        }
    }
}

.forgot-password{
    &.header{
        background: url(asset('login_bg.jpg')) no-repeat;
        background-size: cover;
        background-position: bottom;
        width: 100%;
        height: 144px;
    }
}

#reset-password-user,
#user-verify{

    #reset-password-user-form,
    #verify-user-form{
        margin: 96px 0 48px;
        text-align: center;
        padding: 48px 96px;
        border: 1px solid $color-text-gray-4;
        @media #{$screen-max-sm}{
            margin: 32px 0;
            padding: 24px;
        }
    }
}
