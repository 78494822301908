button{
    //disable ios default settings
    -webkit-appearance: none;
    border-radius: 0;
    @extend %defTransition;
}

.btn{
    min-width: 250px;
    font-size: $font-size-medium;
    @extend %defTransition;
    line-height: normal!important;
    border-radius: 0;
    border: none;
    outline: none;
    &:hover, &:active, &:focus, &:active:focus{
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }

    &.w-flex{
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
}

.btn-primary{
    font-family: Nexa;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    text-transform: uppercase;
    color: $color-white;
    background-color: $color-action-blue;
    padding: 20px 32px;
    &:hover, &:focus{
        background-color: $color-action-blue-hover;
    }
    &:active, &:focus{
        background-color: $color-action-blue-active;
    }

    &:disabled{
        color: $color-text-gray-4;
        border-color: $color-text-gray-4;
        background: $color-text-gray-6;
    }
}

.btn-secondary{
    font-family: Nexa;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    text-transform: uppercase;
    color: $color-action-blue;
    background: transparent;
    border: 1px solid $color-action-blue;
    padding: 20px 32px;

    &.loader{
        svg{
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -26px 0 0 -62px;
            height: 51px;
            z-index: 11;
        }
    }

    &.disabled{
        color: $color-action-blue !important;
        background: transparent !important;
        border: 1px solid $color-action-blue !important;

        &::before{
            content: '';
            background: #{$color-black} + '44';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }

    &:hover{
        color: $color-action-blue-hover !important;
        background: transparent !important;
        border: 1px solid $color-action-blue-hover !important;
    }
    &:active, &:focus, &:active:focus{
        color: $color-action-blue-active !important;
        background: transparent !important;
        border: 1px solid $color-action-blue-active !important;
    }

    &:disabled{
        color: $color-text-gray-4 !important;
        border: 1px solid $color-text-gray-4 !important;
        background: transparent !important;
    }
}

.btn-blue{
    font-family: Roboto;
    font-style: normal;
    font-size: $font-size-normal;
    line-height: normal;
    text-align: center;
    padding: 12px 48px;
    color: #FFFFFF;
    background: #1BABF9;
    &:hover{
        color: #FFFFFF;
        background-color: $color-action-blue-hover;
    }
    &:active{
        color: #FFFFFF;
        background-color: $color-action-blue-active;
    }
    &.round{
        border-radius: 100px;
    }

}
