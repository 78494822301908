%flex{
    display:flex;
    align-items: center;
    justify-content: center;
}

%flex-start{
    display:flex;
    align-items: center;
    justify-content: flex-start;
}
%flex-end{
    display:flex;
    align-items: center;
    justify-content: flex-end;
}

%flex-space-between{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

%flex-align-center{
    display: flex;
    align-items: center;
}

%list-none{
    list-style: none;
    margin: 0;
    padding: 0;
}


%defTransition {
    $defTransition-prop: all .1s ease-in-out;

    -webkit-transition: $defTransition-prop;
    -moz-transition: $defTransition-prop;
    -ms-transition: $defTransition-prop;
    -o-transition: $defTransition-prop;
    transition: $defTransition-prop;
}
