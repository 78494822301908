.about-us{
    &.our-mission-is{
        background: #EFF1F1;

        p{
            font-size: 14px;
        }

        img{
            width: 48px;
            height: 48px;
        }
    }

    .nav-tabs a{
        width: 50%;
        border: none;
        border-bottom: 1px solid #EFF1F1;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        color: $color-black;

        &.active{
            color: #1BABF9;
            border-bottom: 1px solid #1BABF9;
        }
    }

    .carousel{
        @media #{$screen-md} {
            .carousel-indicators{
                display: none;
            }
            .carousel-control-prev{
                left: -90px;
                span{
                    background: none;
                }
            }
            .carousel-control-next{
                right: -90px;
                span{
                    background: none;
                    transform: rotate(180deg);
                }
            }
        }

        @media #{$screen-max-sm} {
            .carousel-control-prev{
                display: block;
                bottom: -56px;
                top: auto;
                border: 2px solid #798F9C;
                border-radius: 100%;
                width: 32px;
                height: 32px;
                padding-top: 4px;
                left: 50%;
                margin-left: -100px;
                z-index: 20;
                span{
                    background: none;
                }
            }
            .carousel-control-next{
                display: block;
                bottom: -56px;
                top: auto;
                border: 2px solid #798F9C;
                border-radius: 100%;
                width: 32px;
                height: 32px;
                padding-top: 4px;
                left: 50%;
                margin-left: 68px;
                z-index: 20;
                span{
                    background: none;
                    transform: rotate(180deg);
                }
            }
            .carousel-indicators{
                bottom: -67px;
                li{
                    margin: 0 8px;
                    opacity: 1;
                    width: 16px;
                    height: 16px;
                    border-radius: 100%;
                    background: transparent;
                    border: 2px solid #798F9C;
                    &.active{
                        background-color: #798F9C;
                    }
                }
            }
        }
    }

    .card-c{
        padding: 32px 24px;
        border: 1px solid #EFF1F1;
        .body{
            width: 100%;
            h5{
                margin: 0;
                border-bottom: 1px solid #EFF1F1;
                padding-bottom: 8px;
                font-size: 24px;
            }

            ul, li{
                margin: 0;
                padding: 0;
                list-style: none;
                font-weight: bold;
                a{
                    text-decoration: underline;
                    color: $color-black;
                    &:hover{
                        color: #1BABF9;
                    }
                }
            }

        }
    }

    .our-products-services{
        .products{
            .product{
                b{
                    display: block;
                    font-size: 16px;
                    text-decoration: underline;
                    color: $color-black;
                }
                &:hover b{
                    color: #1BABF9;
                }
            }

        }
    }
}

.gray-bg{
    background: #EFF1F1;
}
