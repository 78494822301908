@font-face {
    font-family: 'Roboto bold';
    src: url('/assets/fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-bold-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto Medium';
    src: url('/assets/fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-medium-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-regular-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa bold';
    src: url('/assets/fonts/fontfabric_-_nexa_bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/fontfabric_-_nexa_bold-webfont.woff') format('woff'),
         url('/assets/fonts/fontfabric_-_nexa_bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa heavy italic';
    src: url('/assets/fonts/fontfabric_-_nexa_heavy_italic-webfont.woff2') format('woff2'),
         url('/assets/fonts/fontfabric_-_nexa_heavy_italic-webfont.woff') format('woff'),
         url('/assets/fonts/fontfabric_-_nexa_heavy_italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa regular italic';
    src: url('/assets/fonts/fontfabric_-_nexa_italic-webfont.woff2') format('woff2'),
         url('/assets/fonts/fontfabric_-_nexa_italic-webfont.woff') format('woff'),
         url('/assets/fonts/fontfabric_-_nexa_italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa heavy';
    src: url('/assets/fonts/fontfabric_-_nexa-heavy-webfont.woff2') format('woff2'),
         url('/assets/fonts/fontfabric_-_nexa-heavy-webfont.woff') format('woff'),
         url('/assets/fonts/fontfabric_-_nexa-heavy-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Nexa';
    src: url('/assets/fonts/fontfabric_-_nexa-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/fontfabric_-_nexa-regular-webfont.woff') format('woff'),
         url('/assets/fonts/fontfabric_-_nexa-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

h1{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 56px;
    line-height: 64px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 40px;
        line-height: 48px;
    }
}

h2{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 32px;
        line-height: 48px;
    }
}

h3{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 28px;
        line-height: 40px;
    }
}

h4{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 24px;
        line-height: 32px;
    }
}

h5{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 28px;
    line-height: 40px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 20px;
        line-height: 28px;
    }
}

h6{
    font-family: 'Nexa heavy italic';
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    color: $color-text-gray-1;
    @media #{$screen-max-sm}{
        font-size: 18px;
        line-height: 24px;
    }
}

p{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

p.big{
    font-size: 20px;
    line-height: 32px;
}

small{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

b{
    font-family: 'Roboto bold';
    font-style: normal;
    font-weight: normal;
}

.text-underline{
    text-decoration: underline;
}
