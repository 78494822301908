.form-group.round{
    position: relative;
    width: 100%;

    label{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-4;
        cursor: text;
        margin-bottom: 5px;
    }

    input[type="text"]{
        width: 100%;
        background: $color-white;
        border: 1px solid $color-text-gray-4;
        box-sizing: border-box;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-1;
        padding: 12px 16px 12px 56px;
        border-radius: 100px;
        box-shadow: none;
        outline: none;
        height: 56px;

        &:focus{
            border: 1px solid $color-action-blue-active;
            outline: none;
            box-shadow: none;
        }

    }

    svg{
        position: absolute;
        top: 16px;
        left: 16px;
        path{
            stroke: $color-black-1;
        }
    }
}

.form-group:not(.round){
    position: relative;
    width: 100%;
    margin-bottom: 32px;

    @media #{$screen-max-sm}{
        margin-bottom: 24px;
    }

    label{
        position: absolute;
        left: 16px;
        top: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-4;
        cursor: text;
        @extend %defTransition;

        &.fill{
            top: 10px;
            font-size: $font-size-little;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    textarea,
    custom-select{
        width: 100%;
        background: $color-white;
        border: 1px solid $color-text-gray-4;
        box-sizing: border-box;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-1;
        padding: 26px 16px 10px;
        border-radius: 0;
        box-shadow: none;
        outline: none;
        height: 56px;
        @extend %defTransition;

        &:focus{
            border: 1px solid $color-action-blue-active;
            outline: none;
            box-shadow: none;
        }

        &:disabled{
            background-color: $color-text-gray-6;
        }

    }

    &.has-error input[type="text"],
    &.has-error input[type="number"],
    &.has-error input[type="password"],
    &.has-error input[type="email"]{
        border: 1px solid $color-action-red;
    }

    span.error{
        background: $color-action-red;
        padding: 6px 5px 6px 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-little;
        line-height: 18px;
        color: $color-white;
        width: 100%;
        display: none;
        @extend %defTransition;
    }

    .error.error-2{
        background: rgba(238, 83, 79, 0.12);
        display: none;
        svg{
            width: 28px;
            height: 28px;
            circle{
                fill: #EE534F;
            }
        }
    }

    &.has-error span.error{
        display: block;
    }

    &.has-error .error.error-2{
        display: block;
    }

    ul.autocomplete{
            margin: 0;
            padding: 0;
            position: absolute;
            top:56px;
            left: 0;
            border: 1px solid $color-text-gray-4;
            background: $color-white;
            max-height: 256px;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            list-style: none;
            box-sizing: border-box;
            z-index: 10;

            li{
                cursor: pointer;
                list-style: none;
                margin: 0;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                text-align: left;

                border-left: 2px solid $color-white;
                color: $color-text-gray-1;
                padding: 8px 14px;
                width: 100%;
                display: block;
                &:hover{
                    border-left: 2px solid $color-action-blue;
                    background: #1babf91a;
                    color: $color-action-blue-hover;
                }

                &:active, &.active{
                    border-left: 2px solid $color-action-blue;
                    background: #1babf91a;
                    color: $color-action-blue-active;
                }
            }
    }
}

label.checkbox{
    position: relative;
    display: block;
    margin: 0;
    padding-left: 36px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

}

label.checkbox span{
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #53565A;
    box-sizing: border-box;
}


label.checkbox input:checked + span{
    background: #FFFFFF;
    border: 1px solid #44A878;
    background: url(asset('svg/check-input.svg')) no-repeat center;
}

label.checkbox input{
    position: absolute;
    top: 0;
    left: -30px;
    display: none;
}

.form-group.select-small{
    .custom-select{
        width: 100%;
        background: url(asset('svg/arrow-down.svg'));
        background-repeat: no-repeat;
        background-color: $color-white;
        background-position: calc(100% - 10px) center;
        border: 2px solid $color-text-gray-6;
        box-sizing: border-box;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-1;
        padding: 0 32px 0 16px;
        border-radius: 0;
        box-shadow: none;
        outline: none;
        height: 48px;
        @extend %defTransition;

        &:focus{
            @extend %defTransition;
            border: 1px solid $color-action-blue-active;
            outline: none;
            box-shadow: none;
            background-image: url(asset('svg/arrow-up.svg'));
        }

    }
}

.form-group.select-big{
    .custom-select{
        width: 100%;
        background: url(asset('svg/arrow-down.svg'));
        background-repeat: no-repeat;
        background-color: $color-white;
        background-position: calc(100% - 10px) center;
        border: 1px solid $color-text-gray-4;
        box-sizing: border-box;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-medium;
        color: $color-text-gray-1;
        padding: 24px 32px 6px 16px;
        border-radius: 0;
        box-shadow: none;
        outline: none;
        height: 56px;
        @extend %defTransition;

        &:focus{
            @extend %defTransition;
            border: 1px solid $color-action-blue-active;
            outline: none;
            box-shadow: none;
            background-image: url(asset('svg/arrow-up.svg'));
        }

    }
}

.form-group-classic{
    margin-bottom: 32px;
    & > label{
        font-size: 16px;
    }
    .radio{
        margin-top: 16px;
        label{
            position: relative;
            margin-left: 34px;
            &:before{
                position: absolute;
                content: '';
                top: -2px;
                left: -34px;
                border: 1px solid #798F9C;
                border-radius: 100%;
                background: #fff;
                width: 24px;
                height: 24px;
                @extend %defTransition;
            }
        }

        input{
            display: none;
        }

        input:checked + label:after{
            position: absolute;
            top: 4px;
            left: -28px;
            content: "";
            width: 12px;
            height: 12px;
            background: #1BABF9;
            border-radius: 100%;
            @extend %defTransition;
        }
    }
}
